import React, { PureComponent } from "react";
import { Tabs } from "antd";

import PropTypes from "prop-types";
import Immutable from "immutable";

import MenuItem from "./MenuItem";

const { TabPane } = Tabs;

class BaseMenuList extends PureComponent {
  static propTypes = {
    items: PropTypes.object
  };

  state = {
    order: this.props.items.toList(),
    dropdownMenuItems: Immutable.List()
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!Immutable.is(nextProps.items, this.props.items)) {
      this.setState({
        order: nextProps.items.toList()
      });
    }
  }
  onDrop = (dragItem, dropItem) => {
    const newOrderList = this.state.order.map(o => o.key);
    this.props.onDrop && this.props.onDrop(dragItem, dropItem, newOrderList);
  };

  onMoveItem = (dragItem, dropItem) => {
    if (dragItem.dragType == dropItem.dragType) {
      const itemId = dragItem.key;
      const afterItemId = dropItem.key;

      if (itemId !== this.itemId || afterItemId !== this.afterItemId) {
        const findIndex = id => this.state.order.findIndex(o => o.key === id);

        const arr = this.state.order
          .delete(findIndex(itemId))
          .insert(
            findIndex(afterItemId),
            this.state.order.get(findIndex(itemId))
          );
        this.setState({
          order: arr
        });

        this.itemId = itemId;
        this.afterItemId = afterItemId;
      }
    }
  };

  render() {
    const {
      dragType,
      canDrag,
      canDrop,
      tabPosition,
      listClassName
    } = this.props;

    const { order } = this.state;

    return (
      <Tabs
        className={listClassName && listClassName.menu}
        tabPosition={tabPosition}
      >
        {order.map((item, i) => (
          <TabPane
            tab={
              <MenuItem
                key={item.key}
                item={item}
                dragType={dragType}
                canDrag={canDrag}
                canDrop={canDrop}
                onMoveItem={this.onMoveItem}
                onDrop={this.onDrop}
                className={listClassName && listClassName.item}
              />
            }
            key={item.key}
          />
        ))}
      </Tabs>
    );
  }
}

export default BaseMenuList;
