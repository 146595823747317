import React, { useMemo } from "react";
import PropTypes from "prop-types";
import ImmutablePropTypes from "react-immutable-proptypes";
import Immutable from "immutable";
import { Select } from "antd";

import getLink from "../../../../../../../../common/router/getLink";

import styles from "./viewSelector.less";

function ViewSelector(props) {
  const onSelect = (viewId, option) => {
    const { history, location } = props;
    history.push(getLink(location, "", { viewId }));
  };

  const views = useMemo(
    () => {
      let viewsList =
        (props.views && props.views.valueSeq()) || new Immutable.Seq();

      if (viewsList && viewsList.size > 1) {
        // sort by name keeping 0 view first
        const allRecordsView = viewsList.find(v => v.get("id") === "0");
        const newView = viewsList.find(v => v.get("id") === "$new");

        viewsList = viewsList.filter(
          v => v.get("id") !== "0" && v.get("id") !== "$new"
        );
        viewsList = viewsList
          .filter(c => !!c.get("displayName"))
          .sortBy(c => c.get("displayName"));
        viewsList = allRecordsView ? [allRecordsView, ...viewsList] : viewsList;
        viewsList = newView ? [...viewsList, newView] : viewsList;
        viewsList = Immutable.fromJS(viewsList);
      }

      return viewsList;
    },
    [props.views]
  );

  return (
    <Select
      value={props.viewId}
      defaultValue={props.viewId}
      className={styles.viewsListContainer}
      onSelect={onSelect}
    >
      {views
        .map(view => {
          return (
            <Select.Option key={view.get("id")} title={view.get("displayName")}>
              {view.get("displayName")}
            </Select.Option>
          );
        })
        .toJS()}
    </Select>
  );
}

ViewSelector.propTypes = {
  viewId: PropTypes.string.isRequired,
  views: ImmutablePropTypes.map.isRequired,
  history: PropTypes.object,
  location: PropTypes.object
};

export default ViewSelector;
