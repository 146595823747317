import _ from "lodash";
import React from "react";
import PropTypes from "prop-types";
import { Dropdown } from "antd";
import { withTranslation } from "react-i18next";

import Icon from '../../../common/UI/Icon';
import ButtonTransparent from "../../../common/UI/ButtonTransparent";
import ConfigOverlay from "./Overlay";

import styles from "./config.less";

class Config extends React.PureComponent {
  static propTypes = {
    catalog: PropTypes.object.isRequired,
    settings: PropTypes.object,
    viewMode: PropTypes.string
  };

  state = {
    visible: false
  };

  handleVisibleChange = flag => {
    this.setState({ visible: flag });
  };

  render() {
    return (
      <Dropdown
        dropdownRender={() =>
          <ConfigOverlay
            catalog={this.props.catalog}
            settings={this.props.settings}
            viewMode={this.props.viewMode}
          />
        }
        trigger={["click"]}
        placement="bottomRight"
        onOpenChange={this.handleVisibleChange}
        open={this.state.visible}
      >
        <ButtonTransparent
          className={styles.buttonConfig}
          title={this.props.t("buttons.displayParams")}
        >
          <Icon type="icon setting-10" />
        </ButtonTransparent>
      </Dropdown>
    );
  }
}

export default withTranslation()(Config);
