import React from "react";
import PropTypes from "prop-types";
import Immutable from "immutable";
import ImmutablePropTypes from "react-immutable-proptypes";
import { useResizeDetector } from "react-resize-detector";
import { withTranslation } from "react-i18next";
import _ from "lodash";

import Loading from "../../../common/Loading";
import RecordsTable from "../../../common/UI/RecordsTable";
import HeaderWithDropDown from "../HeaderWithDropDown";
import { connect } from "../../../StateProvider";
import {
  EMPTY_FIELD_ID,
  VALUE_STATUSES,
  ALLOWED_TYPES,
  IMPORT_STATUS,
  RECORD_STATUS_FIELD_ID,
  RECORD_STATUSES
} from "../../../../configs/import";
import FieldApi from "../../../../models/FieldApi";

import styles from "../ImportStyles.less";

const Table = props => {
  const { width = null, height = null, ref } = useResizeDetector();

  return (
    <div id={"importTable"} className={styles.tableWrapper} ref={ref}>
      <RecordsTable width={width} height={height} {...props} />
    </div>
  );
};

class ImportTable extends React.Component {
  state = {
    fieldsToRender: Immutable.List(),
    fieldsWidth: Immutable.Map(),
    fieldsOrder: Immutable.List(),
    additionalFields: Immutable.List()
  };

  componentDidMount() {
    this.setDefaultWidth();

    this.getFieldsToRender();
    this.getAdditionalFields();
    this.getSelectItems();
  }

  componentDidUpdate(prevProps) {
    const { columns, allFields } = this.props;
    const { columns: prevColumns, allFields: prevAllFields } = prevProps;

    const columnsChanged = prevColumns && prevColumns !== columns;
    const columnsSizeChanged = prevColumns && prevColumns.size !== columns.size;
    const allFieldsChanged = prevAllFields && prevAllFields !== allFields;

    if (columnsChanged) {
      this.getFieldsToRender();
      this.getSelectItems();
    }

    if (allFieldsChanged) {
      this.getAdditionalFields();
    }

    if (columnsSizeChanged) {
      this.setDefaultWidth();
    }
  }

  setDefaultWidth = () => {
    const { columns } = this.props;
    let { fieldsWidth } = this.state;

    columns.forEach(column => {
      const columnId = column.get("id");

      if (!fieldsWidth.get(columnId)) {
        fieldsWidth = fieldsWidth.set(columnId, 200);
      }
    });

    this.setState({ fieldsWidth });
  };

  isFieldAvailableToSelect = (field, fieldId) => {
    const { columns } = this.props;

    const selectedFieldId = field.get("id");

    // проверяем доступное для выбора поле уже выбрано в другой колонке или нет
    return !columns.some(
      column =>
        column.get("id") !== fieldId &&
        column.get("selectedFieldId") === selectedFieldId
    );
  };

  /* переделать */
  getFieldsToRender = () => {
    let { fieldsOrder, fieldsToRender } = this.state;
    let { columns } = this.props;

    const _fieldsOrder = fieldsOrder ? fieldsOrder.toJS() : [];

    if (!columns) {
      return;
    }

    columns.forEach((column, fieldIndex) => {
      const parsedFieldId = column.get("id");
      const staticField = column.get("static");

      const index = _fieldsOrder.indexOf(parsedFieldId);

      if (staticField) {
        return;
      }

      if (index) {
        columns = columns.setIn([fieldIndex, "orderId"], index);
      }
    });

    /* расчет порядка столбцов */
    fieldsToRender = columns.sortBy(field => field.get("orderId"));

    this.setState({ fieldsToRender });
  };

  getAdditionalFields = () => {
    /* пустое поле для выпадалки */
    const { EMPTY_FIELD } = this.props;

    let additionalFields = Immutable.List();

    additionalFields = additionalFields.push(EMPTY_FIELD);

    this.setState({ additionalFields });
  };

  /* генерирование возможных значений (не выбранные в других полях и не пустые) для выпадалки */
  getSelectItems = () => {
    let { allFields } = this.props;

    const availableFields = allFields.filter(field => {
      const fieldId = field.get("id");
      return fieldId !== EMPTY_FIELD_ID;
    });

    this.setState({ availableFields });
  };

  /* изменение порядка колонок */
  onReorder = fieldsOrder => {
    this.setState(
      () => ({
        fieldsOrder: fieldsOrder
      }),
      this.getFieldsToRender
    );
  };
  /* изменение размера колонок */
  onResize = (width, fieldId) => {
    let { fieldsWidth } = this.state;

    fieldsWidth = fieldsWidth.set(fieldId, width);
    this.setState(() => ({
      fieldsWidth
    }));
  };

  /* выбор типа поля из каталога */
  onSelectField = (columnId, selectedFieldId) => {
    let { allFields, onChangeColumnField } = this.props;

    if (!columnId || !selectedFieldId) {
      return;
    }

    const field = allFields.find(field => field.get("id") === selectedFieldId);

    onChangeColumnField && onChangeColumnField(columnId, field);
  };

  isCellEditable = (field, record) => {
    // check Api Only
    const apiOnly = field && field.get("apiOnly");
    if ( apiOnly ) {
      return false;
    }

    // check Static?
    const staticField = field.get("static");
    if ( staticField ) {
      return false;
    }

    // check Disabled?
    const disabled = field.get("disabled");
    if ( disabled ) {
      return false;
    }

    // check Import Row Status
    const recordStatus = record.getIn(["values", RECORD_STATUS_FIELD_ID, "0"]);
    const importDone =
      recordStatus === RECORD_STATUSES.IMPORT_COMPLETED ||
      recordStatus === RECORD_STATUSES.EXCLUDED_FROM_IMPORT;
    if ( importDone ) {
      return false;
    }
    
    return true;
  };

  getCellClassName = (field, record) => {
    const editable = this.isCellEditable(field, record);

    const recordId = record && record.get("id");
    const fieldId = field && field.get("id");

    if (!editable) {
      return styles.notSelected;
    }

    const { valuesStatus } = this.props;

    const valueConvertStatus =
      valuesStatus && valuesStatus.getIn([fieldId, recordId]);

    if (valueConvertStatus === VALUE_STATUSES.VALID) {
      return styles.convertCompleted;
    } else if (valueConvertStatus === VALUE_STATUSES.INVALID) {
      return styles.convertFailed;
    } else {
      return styles.notSelected;
    }
  };

  validateValueByField = (value, field) => {
    try {
      return FieldApi.validateValue(value, field);
    } catch (error) {
      console.warn(error);
      return true;
    }
  };

  CustomHeaderComponent = ({ field }) => {
    const { catalogName, columns } = this.props;
    const { availableFields, additionalFields } = this.state;

    /* компонент выпадалки в хедере */
    if (!field) {
      return null;
    } else {
      const fieldId = field.get("id");
      const fieldName = field.get("name");
      return (
        <HeaderWithDropDown
          additionalFields={additionalFields}
          availableFields={availableFields}
          fields={columns}
          catalogName={catalogName}
          onSelectItem={this.onSelectField}
          filterFn={this.isFieldAvailableToSelect}
          fieldId={fieldId}
          fieldName={fieldName}
        />
      );
    }
  };

  render() {
    let {
      catalogId,
      sectionId,
      sceneId,
      colorField,
      columns,
      records,
      footerStatus,
      loading,

      onChangeRecordValue,
      onDeleteRecord,
      onRestoreRecord,
      t
    } = this.props;

    const { fieldsWidth, fieldsOrder, fieldsToRender } = this.state;

    const rowsCount = loading
      ? (records && records.size) || 50
      : records && records.size;
    records =
      records && Immutable.Map.isMap(records) ? records : Immutable.Map({});

    return !(columns && columns.isEmpty()) ? (
      <Table
        sceneId={sceneId}
        sectionId={sectionId}
        catalogId={catalogId}
        records={records} /* records by offset currentValues */
        fieldsToRender={fieldsToRender} /* fields */
        allFields={columns} /* raw fields */
        rowsCount={rowsCount}

        colorField={colorField} // по какому полю будет происходить покраска
        fieldsWidth={fieldsWidth}
        fieldsOrder={fieldsOrder}
        
        rowByIndex={true}
        applyVisibleConfig={false}
        isReorderable={false}
        loading={loading}
        
        isCellEditable={this.isCellEditable}
        getCellClassName={this.getCellClassName}
        validateValueByField={this.validateValueByField}

        headerHeight={70}
        CustomHeaderComponent={this.CustomHeaderComponent}
        footerData={footerStatus}

        onChangeRecordValue={onChangeRecordValue}
        onReorder={this.onReorder}
        onResize={this.onResize}
        onDeleteRow={onDeleteRecord}
        onRestoreRow={onRestoreRecord}
      />
    ) : (
      <Loading
        fullHeight
        text={t("import.key.loading")}
        className={styles.loading}
      />
    );
    // }
  }
}

ImportTable.propTypes = {
  columns: ImmutablePropTypes.list,
  records: ImmutablePropTypes.map,
  catalogId: PropTypes.string.isRequired,
  sectionId: PropTypes.string.isRequired
};

export default withTranslation()(
  connect(
    ImportTable,
    {
      columns: ["import", "columns"],
      records: ["import", "filteredRecords"],
      rowsCount: ["import", "rowsCount"],
      loading: ["import", "loading"],
      valuesStatus: ["import", "valuesStatus"],
      footerStatus: ["import", "footerStatus"],
      catalogs: ["catalogs"],
      EMPTY_FIELD: ["import", EMPTY_FIELD_ID],
      importStatus: ["import", "importStatus"]
    },
    (props, { catalogs, EMPTY_FIELD, importStatus, ...state }) => {
      const { catalogId } = props;

      const defaultFields = Immutable.fromJS([EMPTY_FIELD]);
      const fildsFromCatalog = catalogs
        .getIn([catalogId, "fields"])
        .filter(field => ALLOWED_TYPES.includes(field.get("type")));
      const allFields = defaultFields.concat(fildsFromCatalog);
      const catalogName = catalogs.getIn([catalogId, "name"]);

      const saving = importStatus === IMPORT_STATUS.SAVING.STATUS;

      return {
        allFields,
        EMPTY_FIELD,
        catalogName,
        saving,
        ...props,
        ...state
      };
    }
  )
);
