import React, { useState } from "react";
import _ from "lodash";
import { Popover} from "antd";
import { useTranslation } from "react-i18next";

import { withRouter } from "react-router";

import Logo from "../Logo";
import Icon from '../../../common/UI/Icon';
import ButtonTransparent from "../../../common/UI/ButtonTransparent";
import { connect } from "../../../StateProvider";
import apiActions from "../../../../actions/apiActions";
import { prompt } from "../../../common/Modal";
import PopoverContent from "./PopoverContent";
import routes from "../../../../routes";

import styles from "./companies.less";

const IconForSectionDefault = "content-3";

const CompaniesMenu = props => {
  const IconForSectionDefault = "content-3";
  let {
    companies,
    sections,
    catalogs,
    favoriteCatalogs,
    company,
    history
  } = props;
  const [visible, setVisible] = useState(false);
  const { t } = useTranslation();

  const handleClickCreacteCompany = () => {
    window.open(routes.register.path);
  };

  const onCreateSection = name => {
    apiActions
      .createSection(
        {},
        {
          name: name,
          icon: IconForSectionDefault
        }
      )
      .then(res => {
        history.push(`/section/${res.id}`);
      });
  };

  const hidePopover = () => {
    setVisible(false);
  };

  const handleVisibleChange = show => {
    setVisible(show);
  };

  const openCreateModal = () => {
    hidePopover();

    prompt({
      headerText: t("modals.createSection.headerText"),
      placeHolder: t("modals.createSection.inputPlaceHolder"),
      okText: t("buttons.create"),
      cancelText: t("buttons.cancel"),
      onOk: onCreateSection
    });
  };

  const popoverContent = (
    <PopoverContent
      hidePopover={hidePopover}
      handleClickCreacteCompany={handleClickCreacteCompany}
      openCreateModal={openCreateModal}
      sections={sections}
      catalogs={catalogs}
      favoriteCatalogs={favoriteCatalogs}
      companies={companies}
      company={company}
      visible={visible}
    />
  );

  return companies ? (
    <Popover
      onOpenChange={handleVisibleChange}
      open={visible}
      content={popoverContent}
      className={styles.popoverWrapper}
      trigger="click"
      placement="bottomLeft"
      overlayClassName={styles.popoverWrapperContent}
      getPopupContainer={triggerNode => triggerNode.parentNode}
    >
      <ButtonTransparent
        className={styles.mainMenuButton}
        light
        title={t("menuPopover.menu")}
      >
        <Icon type="icon content-43" className={styles.mainMenuIcon} />
        <Logo />
      </ButtonTransparent>
    </Popover>
  ) : (
    <Logo />
  );
};

export default connect(
  withRouter(CompaniesMenu),
  {
    sections: ["sections"],
    catalogs: ["catalogs"],
    companies: ["companies"],
    company: ["company"],
    favoriteCatalogs: ["userSettings", "ui", "favoriteCatalogs"]
  }
);
