import Immutable from "immutable";
import _ from "lodash";

import { chartDataKeyPrefix } from "./valuesPrefix";

import {DESC, VALUE} from "../../../../configs/reports/widget/sortTypes"

const getStoreKey = uid => [...chartDataKeyPrefix, uid, "values"];

export function getValues({ widgetId }, query, { uid }) {
  if (!this.getIn(chartDataKeyPrefix)) {
    this.setIn(chartDataKeyPrefix, Immutable.Map());
  }

  const key = getStoreKey(uid);

  if (!this.getIn(key)) {
    this.setIn(key, Immutable.Map());
  }

  this.setIn([...key, "loading"], true);

  this.changed();
}

export function getStatisticsValue(...args) {
  getValues.bind(this, ...args)();
}

function sortValues(data, sortType){

  data = _.sortBy(data, item => (
    item.values.reduce((total, value) => total + (value.value || 0), 0 )  
  ))

  if(sortType === DESC){
    data = data.reverse()
  }

  return data
}

export function getValuesCompleted(
  data,
  { widgetId },
  postData,
  {sortType, sort},
  res,
  { uid }
) {
  if(sort === VALUE){
    data = sortValues(data, sortType)
  }
  const key = getStoreKey(uid);
  this.setIn([...key, "data"], data);
  this.setIn([...key, "loading"], false);
  this.setIn([...key, "error"], null);

  this.changed();
}

export function getStatisticsValueCompleted(...args) {
  getValuesCompleted.bind(this, ...args)();
}

export function getValuesFailed(err, { widgetId }, data, query, { uid }) {
  const key = getStoreKey(uid);
  this.setIn([...key, "error"], err);
  this.setIn([...key, "loading"], false);

  this.changed();
}

export function getStatisticsValueFailed(...args) {
  getValuesFailed.bind(this, ...args)();
}

export function clearChartData(uid) {
  this.setIn([...chartDataKeyPrefix, uid], Immutable.Map());
  this.changed();
}
