import React, { Component } from "react";
import _ from "lodash";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { connect } from "../../../StateProvider";
import PropTypes from "prop-types";
import { dragSource, dropTarget } from "./dndSourceTarget";

import styles from "./abstractMenu.less";

class MenuItem extends Component {
  static propTypes = {
    item: PropTypes.object.isRequired,
    dropType: PropTypes.any,
    dropInfo: PropTypes.any
  };

  state = {
    iconHovered: false
  };

  onMouseEnter = e => {
    this.setState({
      iconHovered: true
    });
  };

  onMouseLeave = e => {
    this.setState({
      iconHovered: false
    });
  };

  render() {
    const {
      connectDragSource,
      connectDragPreview,
      connectDropTarget,
      isDragging,
      item,
      className,
      isDraggingOver,
      canDrop
    } = this.props;

    let draggingStyle = null;
    if (isDragging) {
      draggingStyle = { opacity: "0.1" };
    }
    if (isDraggingOver && canDrop) {
      draggingStyle = { opacity: "0.1" };
    }

    return _.flow(
      connectDragSource,
      connectDragPreview,
      connectDropTarget
    )(
      <li
        className={className}
        onMouseEnter={this.onMouseEnter}
        onMouseLeave={this.onMouseLeave}
        style={draggingStyle}
      >
        {item}
      </li>
    );
  }
}

export default withRouter(
  withTranslation()(
    connect(
      _.flow(
        dragSource,
        dropTarget
      )(MenuItem),
      ["catalogs"]
    )
  )
);
