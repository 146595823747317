import React from "react";
import PureRenderMixin from "react-addons-pure-render-mixin";
import cn from "classnames";
import _ from "lodash";
import PropTypes from "prop-types";

import Icon from '../../Icon';

import styles from "./controls.less";

export const MAX_STAR_SIZE = 5;

class Stars extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      value: this.props.value
    };
  }

  onClickStar = value => {
    if (!this.props.editable) {
      return;
    }

    if (value === 1 && this.state.value === 1) {
      value = 0;
    }
    this.setState({ value });

    this.props.onChange && this.props.onChange(value);
    this.props.onEndEditing && this.props.onEndEditing(value);
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.value !== nextProps.value) {
      this.setState({
        value: nextProps.value
      });
    }
  }

  render() {
    var stars = [];

    for (var i = 0; i < MAX_STAR_SIZE; i++) {
      stars.push(
        <Icon
          key={i}
          type="icon vote-38"
          className={cn(this.props.className, styles.starsField, {
            [styles.starsFieldSelected]: i < this.state.value,
            [styles.readOnly]: !this.props.editable
          })}
          onClick={_.bind(this.onClickStar, this, i + 1)}
        />
      );
    }

    return <div className={styles.starsContainer}>{stars}</div>;
  }
}

Stars.propTypes = {
  value: PropTypes.number,
  onChange: PropTypes.func,
  onEndEditing: PropTypes.func,
  editable: PropTypes.bool
};

export default Stars;
