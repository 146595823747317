import React, { Component } from "react";
import { Row, Button } from "antd";
import ButtonClose from "../../../../../ButtonClose";
import { withTranslation } from "react-i18next";

import styles from "./modal.less";

class Modal extends Component {
  render() {
    const { file, onClose, viewerComponent } = this.props;

    return (
      <div className={styles.modal}>
        <Row
          type="flex"
          justify="space-between"
          align="middle"
          className={styles.modalHeader}
        >
          <h2 className="text-overflow-break">{file.title}</h2>
          <Row type="flex" align="middle">
            <Button>
              <a
                download="download"
                target="_blank"
                className={styles.downloadLink}
                href={file.url}
              >
                {this.props.t("buttons.download")}
              </a>
            </Button>
            <ButtonClose
              className={styles.modalClose}
              large
              onClick={onClose}
            />
          </Row>
        </Row>
        <div>{viewerComponent && viewerComponent(file)}</div>
      </div>
    );
  }
}

export default withTranslation()(Modal);
