import { DragSource, DropTarget } from "react-dnd";
import dndTargets from "../../../../../configs/dndTargets";
import dragAndDropActions from "../../../../../actions/dragAndDropActions";
import resourceTypes from "../../../../../configs/resourceTypes";

export const dragSource = DragSource(
  dndTargets.SIDEBAR_ITEM,
  {
    // передает объект в endDrag
    beginDrag(props) {
      const item = {
        key: props.item.key,
        dragType: props.dragType
      };
      return item;
    },
    // true, если компонент может быть перетаксиваемым
    canDrag(props, monitor) {
      const dragItem = props.item;
      if (props.canDrag) {
        return props.canDrag(dragItem);
      }
    }
  },
  function collect(connect, monitor) {
    return {
      connectDragSource: connect.dragSource(),
      connectDragPreview: connect.dragPreview(),
      isDragging: monitor.isDragging()
    };
  }
);

export const dropTarget = DropTarget(
  dndTargets.SIDEBAR_ITEM,
  {
    //сбрасывание элемента на целевой компонент
    drop: function(props, monitor, component) {
      const dragItem = monitor.getItem();
      if (props.onDrop) {
        const dropItem = { ...props.item, dragType: props.dragType };
        props.onDrop(dragItem, dropItem);
      }
    },
    //наведение перестакиваемого элемента на целевой элемент
    hover(props, monitor) {
      if (monitor.canDrop()) {
        const dragItem = monitor.getItem();
        const dropItem = { ...props.item, dragType: props.dragType };
        props.onMoveItem(dragItem, dropItem);
      }
    },
    //true, если целевой компонент может принять сброшенный эл-т
    canDrop(props, monitor) {
      const dragItem = monitor.getItem();
      const dropItem = { ...props.item, dragType: props.dragType };
      // console.log("abstract canDrop");
      const allowDrop = props.canDrop
        ? props.canDrop(dragItem, dropItem)
        : dragItem.dragType === dropItem.dragType;

      if (allowDrop) {
        return dragItem; // key and dragType
      }
    }
  },
  function collect(connect, monitor, props) {
    return {
      connectDropTarget: connect.dropTarget(),
      isDraggingOver: monitor.isOver(),
      canDrop: monitor.canDrop()
    };
  }
);
