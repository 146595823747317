import _ from "lodash";
import Reflux from "reflux";

import makeApiRequest from "../utils/makeApiRequest";
import antiCapitalize from "../utils/antiCapitalize";

export const TYPES = {
  SECTIONS: "sections",
  CATALOGS: "catalogs",
  USERS: "users",
  LINKED_OBJECTS: "linkedObjects",
  SUBJECTS: "subjects",
  LINKED_OBJECTS_WITH_FILTERS: "linkedObjectsWithFilters",
  RECORDS: "linkedRecords",
  VIEWS: "views"
};

const actions = {};

const dropdownHandlers = {
  [TYPES.SECTIONS]: function(...args) {
    return makeApiRequest("sections", {}, true).then(res => {
      return res.body.map(section => {
        return {
          key: section.id,
          text: section.name
        };
      });
    });
  },

  [TYPES.CATALOGS]: function(...args) {
    return makeApiRequest("catalogs", {}, true).then(res => {
      return res.body.map(catalog => {
        return {
          key: catalog.id,
          text: catalog.name,
          icon: catalog.icon
        };
      });
    });
  },

  [TYPES.USERS]: function(params) {
    const query = {};
    if (params.title) {
      query.title = params.title;
    }
    return makeApiRequest("users", { query }, true).then(res => {
      return res.body.map(o => {
        return {
          key: o.id,
          text: o.title,
          icon: "users-1",
          alwaysVisible: o.alwaysVisible,
          sort: ~~o.sort
        };
      });
    });
  },

  [TYPES.LINKED_OBJECTS]: function(params) {
    const query = {};
    if (params.title) {
      query.title = params.title;
    }
    if (params.linkedCatalogId) {
      query.catalogId = params.linkedCatalogId;
    }
    if (!_.isEmpty(params.recordsFilters)) {
      query.recordsFilters = JSON.stringify(params.recordsFilters);
    }
    return makeApiRequest(
      "catalogs/" +
        params.catalogId +
        "/fields/" +
        params.fieldId +
        "/availableRecords",
      { query },
      true
    ).then(res => {
      return res.body.map(o => {
        return {
          key: o.catalogId + ":" + o.recordId,
          text: o.recordTitle,
          icon: o.catalogIcon,
          item: o
        };
      });
    });
  },

  [TYPES.SUBJECTS]: function(params) {
    let opts;
    let title = params && params.title;

    if (title) {
      opts = { query: { title: params.title } };
    }

    return makeApiRequest("availableRightSubjects", opts, true).then(res => {
      return res.body.map(c => {
        return {
          key: c.userAttr + ":" + c.catalogId + ":" + c.recordId,
          text: c.recordTitle,
          subText: c.userAttrTitle && antiCapitalize(c.userAttrTitle),
          icon: c.catalogIcon,
          subject: c
        };
      });
    });
  },

  [TYPES.RECORDS]: function(params) {
    return makeApiRequest(
      "catalogs/" + params.catalogId + "/records",
      { query: { searchText: params.title } },
      true
    ).then(res => {
      return res.body.map(item => {
        return {
          key: item.id,
          text: item.title,
          item
        };
      });
    });
  },

  [TYPES.LINKED_OBJECTS_WITH_FILTERS]: function(params) {
    return makeApiRequest(
      "catalogs/" +
        params.catalogId +
        "/fields/" +
        params.fieldId +
        "/availableFilterRecords",
      { query: { title: params.title } },
      true
    ).then(res => {
      return res.body.map(item => {
        return {
          key: item.catalogId + ":" + item.recordId,
          text: item.recordTitle,
          icon: item.catalogIcon,
          item: item
        };
      });
    });
  },

  [TYPES.VIEWS]: function(params) {
    return makeApiRequest("views", {}, true).then(res => {
      return res.body.filter(v => v.forRights).map(view => {
        return {
          key: "view:" + view.id,
          text:
            view.catalogTitle +
            " / " +
            (view.originName ? view.originName : view.name),
          catalogId: String(view.catalogId),
          icon: view.catalogIcon
        };
      });
    });
  }
};

actions.clearAvailableItems = Reflux.createAction();

actions.loadAvailableItems = Reflux.createAction({ asyncResult: true });
actions.loadAvailableItems.listen(function(type, storeKey, params) {
  if (!dropdownHandlers[type]) {
    throw new Error(`No dropdownHandlers for type '${type}'`);
  }

  return dropdownHandlers[type]
    .call(this, params)
    .then(items => {
      this.completed(type, storeKey, params, items);
    })
    .catch(err => {
      this.failed(type, storeKey, params, err);
    });
});

export default actions;
