import React, { PureComponent } from "react";
import { Row } from "antd";
import cn from "classnames";
import { withTranslation } from "react-i18next";
import _ from "lodash";

import Icon from "../../common/UI/Icon";
import ButtonTransparent from "../../common/UI/ButtonTransparent";
import AppUpdateIndicator from "./AppUpdateIndicator";
import LanguageSwitcher from "../../common/widgets/LanguageSwitcher";

import { connect } from "../../StateProvider";
import sceneActions from "../../../actions/sceneActions";

import styles from "./appHeader.less";
import MultiModal from "../../common/Modal/MultiModal";
import SCENE_CONTAINER from "../../../configs/sceneContainer";
import Helper from "../../common/widgets/Helper/Helper";
import UserMenu from "./UserMenu";
import Chats from "./Chats";
import ChatraHelper from "../../common/chatraHelper";

class Settings extends PureComponent {
  openMultimodal = () => {
    sceneActions.setVisible(true);
  };

  render() {
    let { modalScenesCount, showPicker = true } = this.props;

    const chatraModule = _.get(window, ["Chatra"]);
    return (
      <Row
        type="flex"
        justify="space-around"
        align="middle"
        className={styles.profile}
      >
        <div className={styles.profileButtonsContainer}>
          {/* <img src="favicon.ico" alt="profile" /> */}
          {modalScenesCount !== 0 && (
            <ButtonTransparent
              onClick={this.openMultimodal}
              className={cn(
                "rc-color-picker-trigger btn-glass btn-light",
                styles.profileItem
              )}
              light
              title={this.props.t("multiModal.open")}
            >
              <div className={styles.buttonContainer}>
                <Icon type="icon programing-21" />
                {!!modalScenesCount && (
                  <div className={cn(styles.notify)}>
                    {<span>{modalScenesCount}</span>}
                  </div>
                )}
              </div>
            </ButtonTransparent>
          )}

          <Chats />
        </div>

        <AppUpdateIndicator />

        <MultiModal />

        {chatraModule && (
          <ChatraHelper chatraModule={chatraModule} t={this.props.t} />
        )}

        <Helper />

        <LanguageSwitcher />

        <UserMenu />
      </Row>
    );
  }
}

export default withTranslation()(
  connect(
    Settings,
    {
      scenes: ["scenes"]
    },
    (props, state) => {
      const modalScenesCount =
        state.scenes &&
        state.scenes.filter(
          scene => scene.get("container") === SCENE_CONTAINER.POPUP
        ).size;

      return {
        modalScenesCount,
        ...props,
        ...state
      };
    }
  )
);
