import React from "react";
import PropTypes from "prop-types";
import cn from "classnames";
import _ from "lodash";
import { useTranslation } from "react-i18next";

import TextInput from "./common/TextInput";

import styles from "./controls.less";
import { Row } from "antd";

const MAX_SAFE_INTEGER = Number.MAX_SAFE_INTEGER || Math.pow(2, 53) - 1;
const MIN_SAFE_INTEGER = Number.MIN_SAFE_INTEGER || -MAX_SAFE_INTEGER;

const langs = {
  ru: "ru-RU",
  en: "en-US"
};

function getSeparator(locale, decimalType = "decimal", groupType = "group") {
  const numberWithGroupAndDecimalSeparator = 10002323.1;
  try {
    /**
     * Intl format to parts not supported by old Safari browser
     */
    const parts = Intl.NumberFormat(locale).formatToParts(
      numberWithGroupAndDecimalSeparator
    );
    return {
      decimal: parts.find(part => part.type === decimalType).value,
      group: parts.find(part => part.type === groupType).value
    };
  } catch (err) {
    return {
      decimal: ".",
      group: " "
    };
  }
}

const NumberComponent = props => {
  const {
    updateProcess,
    eventable,
    value,
    editable,
    onChange,
    onEndEditing,
    onBlur,
    htmlId,
    autoFocus,
    onKeyPress,
    className
  } = props;

  const {
    i18n: { language }
  } = useTranslation();
  const unit = props.config.get("unit");
  const locale = langs[language];

  // let min = props.config.get("min");
  // min = min || min === 0 || min === "0" ? min * 1 : Number.MIN_SAFE_INTEGER;
  // let max = props.config.get("max");
  // max = max || max === 0 || max === "0" ? max * 1 : Number.MAX_SAFE_INTEGER;
  const disabledClassName = cn({ [styles.numberInputDisabled]: !editable });
  const resultClassName = cn(
    className || styles.numberInput,
    disabledClassName
  );

  const shouldDisplay = value => {
    // Suppose to validate data before allow to display input
    const isValueValid =
      !_.isUndefined(value) && !_.isNull(value) && !_.isNaN(value);

    return isValueValid || editable;
  };

  const prepareNumber = value => {
    const { decimal, group } = getSeparator(locale);
    if (value.indexOf("-") !== 0 && value.indexOf("-") > -1) {
      return value.replace("-", "");
    }

    if (value !== "") {
      const haveDecimal = new RegExp("\\" + decimal, "g");
      value = String(value).replace(haveDecimal, ".");
      value = value.replaceAll(/[a-zA-Zа-яА-Я]/g, "");
      const reg = new RegExp("[^d" + decimal + "-]*", "g");
      //value = value.replace(reg, "");
      value = value.replaceAll(group, "");
      if (
        isNaN(parseFloat(value)) ||
        !_.inRange(parseFloat(value), MIN_SAFE_INTEGER, MAX_SAFE_INTEGER)
      ) {
        value = "";
      } else {
        value = parseFloat(value);
      }
    }

    if (value === "") {
      value = null;
    }
    return _.has(value, "toLocaleString")
      ? value.toLocaleString(locale)
      : value;
  };

  /* Specifies the format of the value presented */
  const formatter = value => {
    /**
     * Format value when typing
     * Now it deletes all letters and divide didgit by groups using locale characters
     *
     * FIXME: if someone insert letters or - symbol into number, oops gonna happen
     */
    value = String(value);
    let minusExist = value.indexOf("-") === 0;
    const { decimal, group } = getSeparator(locale);
    value = value.replaceAll("-", "");
    value = value.replaceAll(/[a-zA-Zа-яА-Я]/g, "");
    let parts = value.toString().split(decimal);
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, group);
    const res =
      (minusExist ? "-" : "") + parts.join(decimal).toLocaleString(locale);
    return res;
  };

  return (
    <div
      className={cn(styles.numberWrapper, {
        [styles.numberWrapperDisabled]: !editable
      })}
    >
      <TextInput
        id={htmlId}
        type="number"
        value={value}
        // min={min}
        // max={max}
        onChange={onChange}
        onEndEditing={onEndEditing}
        onBlur={onBlur}
        prepareNumber={prepareNumber}
        formatter={formatter}
        onKeyPress={onKeyPress}
        readOnly={!editable}
        autoFocus={autoFocus}
        eventable={eventable}
        updateProcess={updateProcess}
        className={resultClassName}
        wrapperClassName={styles.numberInputWrapper}
      />
      {shouldDisplay(value) && unit ? (
        <span className={styles.numberUnit}>{unit}</span>
      ) : null}
    </div>
  );
};

NumberComponent.propTypes = {
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  config: PropTypes.object,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  onEndEditing: PropTypes.func,
  editable: PropTypes.bool,
  eventable: PropTypes.bool,
  error: PropTypes.string
};

export default NumberComponent;
