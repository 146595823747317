import _ from "lodash";
import React from "react";
import { Menu as AntMenu } from "antd";
import PropTypes from "prop-types";
import cn from "classnames";
import { useTranslation } from "react-i18next";

import styles from "./companies.less";

const PopoverSideMenu = props => {
  const { t } = useTranslation();
  const {
    selectedItems,
    menuItems,
    showSideSearch,
    showAddCompanyButton
  } = props;

  const showHeader = showSideSearch || showAddCompanyButton;

  const items = _.isEmpty(menuItems) 
    ? [{
        key: 0,
        label: (
        <div className={styles.emptySearch}>
          <p>{t("menuPopover.searchSideEmpty")}</p>
        </div>
      )
    }]
    : _.map(menuItems, item => item);

  return (
    <AntMenu
      className={cn(styles.popoverSideMenu, {
        [styles.withinHeader]: showHeader,
        [styles.withoutHeader]: !showHeader
      })}
      style={{ padding: "0" }}
      items={items}
      selectedKeys={[selectedItems]}
    >
    </AntMenu>
  );
};

PopoverSideMenu.propTypes = {
  currentLocationIndex: PropTypes.string,
  menuItems: PropTypes.array,
  showSideSearch: PropTypes.bool
};

export default PopoverSideMenu;
