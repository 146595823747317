import React from "react";
import cn from "classnames";

import BaseMenuList from "./BaseMenuList";
import dndContext from "../../../../services/dndContext";

import styles from "./abstractMenu.less";

const AbstractMenu = ({ tabPosition = "top", direction = "row", ...props }) => {
  const { vertical, horizontal } = props;
  let listClassName = {
    menu: cn(horizontal?.menu, styles.menuHorizontal),
    item: cn(horizontal?.item, styles.menuItem)
  };
  if (direction === "column" || direction === "column-reverse") {
    tabPosition = "left";
    listClassName = {
      ...vertical,
      menu: cn(vertical.menu, styles.menuVertical)
    };
  }

  return (
    <BaseMenuList
      tabPosition={tabPosition}
      listClassName={listClassName}
      {...props}
    />
  );
};

export default dndContext(AbstractMenu);
