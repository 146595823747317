import React from "react";
import PropTypes from "prop-types";
import cn from "classnames";
import { withTranslation } from "react-i18next";

import { connect } from "../StateProvider";

import styles from "./controls.less";
import Icon from "./UI/Icon";

class HelpIcon extends React.PureComponent {
  render() {
    const { props } = this;
    return (
      <a
        href={props.docsUrl + props.helpPath}
        target={props.inBlank ? "_blank" : null}
        title={props.helpTitle ? props.helpTitle : props.t("buttons.help")}
      >
        <Icon type="icon objects-66"></Icon>
      </a>
    );
  }
}

HelpIcon.defaultProps = {
  helpPath: "",
  icon: "anticon-icon interface-56",
  inBlank: true,
  docsUrl: ""
};

HelpIcon.propTypes = {
  docsUrl: PropTypes.string,
  helpPath: PropTypes.string.isRequired,
  helpTitle: PropTypes.string,
  icon: PropTypes.string,
  inBlank: PropTypes.bool
};

export default connect(
  withTranslation()(HelpIcon),
  { docsUrl: ["vendor", "docsUrl"] }
);
