export default {
  TAB: "tab",
  GROUP: "group",

  TEXT: "text",
  CODE: "code",
  NUMBER: "number",
  DATE: "date",
  CONTACT: "contact",
  ADDRESS: "address",

  SWITCH: "switch",
  DROPDOWN: "dropdown",
  CHECKBOXES: "checkboxes",
  RADIOBUTTON: "radiobutton",

  PROGRESS: "progress",
  STARS: "stars",

  USER: "user",
  OBJECT: "object",
  FILE: "file",

  PAIR: "pair",

  BUTTON: "button"
};

export const WHITE_LIST = {
  GROUP: "group",

  TEXT: "text",
  NUMBER: "number",
  DATE: "date",
  CONTACT: "contact",
  ADDRESS: "address",

  SWITCH: "switch",
  DROPDOWN: "dropdown",
  CHECKBOXES: "checkboxes",
  RADIOBUTTON: "radiobutton",

  OBJECT: "object",
  USER: "user",
  FILE: "file",

  PROGRESS: "progress",
  STARS: "stars",

  BUTTON: "button"
};

export const INPUT_LIST = {
  NUMBER: "number",
  MULTILINE: "multiline",
  TEXT_EDITOR: "textEditor",
  MASK: ""
};

export const OBJECT_MODS = {
  LIST: "list",
  CARDS: "cards",
  TABLE: "table",
  INLINE: "inline"
};
