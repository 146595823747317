import React, { useEffect, useState } from "react";
import { Radio } from "antd";
import { useTranslation } from "react-i18next";
import Icon from "../../common/UI/Icon";
import styles from "./header.less";
import { BOARD_VIEW_MODS } from "../../../configs/boardViewMode";

export default props => {
  const { toggleColsWidthMode, colsMode } = props;
  const { t } = useTranslation();
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [valueMode, setValueMode] = useState(colsMode);

  useEffect(() => {
    const handleResize = () => {
      const newWidth = window.innerWidth;
      setWindowWidth(newWidth);

      if (newWidth < 1576) {
        setValueMode(null);
      }
    };
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  useEffect(
    () => {
      setValueMode(prev => colsMode);
    },
    [colsMode]
  );

  const toggleMode = mode => {
    if (mode === BOARD_VIEW_MODS.SMALL) {
      if (windowWidth > 1576) {
        toggleColsWidthMode(mode);
        setValueMode(mode);
      } else return;
    } else if (mode === BOARD_VIEW_MODS.MEDIUM) {
      if (windowWidth > 1076) {
        toggleColsWidthMode(mode);
        setValueMode(mode);
      } else return;
    }
    toggleColsWidthMode(mode);
    setValueMode(mode);
  };
  return (
    <div className={styles.viewTabsContainer}>
      <label htmlFor="mode">{t("fieldTypes.object.mode.label")}</label>
      <Radio.Group
        id={"mode"}
        name={"mode"}
        defaultValue={valueMode}
        value={valueMode}
        className={styles.iconGroup}
      >
        <div title={t("buttons.oneColumn")}>
          <Radio.Button
            value={BOARD_VIEW_MODS.LARGE === valueMode && BOARD_VIEW_MODS.LARGE}
            onClick={() => toggleMode(BOARD_VIEW_MODS.LARGE)}
          >
            <Icon className={styles.BoardViewSquare} />
          </Radio.Button>
        </div>
        <div title={t("buttons.twoColumns")}>
          <Radio.Button
            value={
              BOARD_VIEW_MODS.MEDIUM === valueMode && BOARD_VIEW_MODS.MEDIUM
            }
            onClick={() => toggleMode(BOARD_VIEW_MODS.MEDIUM)}
            disabled={windowWidth > 1076 ? false : true}
          >
            <Icon
              type="icon anticon-icon content-42"
              className={styles.BoardViewIcon}
            />
          </Radio.Button>
        </div>
        <div title={t("buttons.threeColumns")}>
          <Radio.Button
            value={BOARD_VIEW_MODS.SMALL === valueMode && BOARD_VIEW_MODS.SMALL}
            onClick={() => toggleMode(BOARD_VIEW_MODS.SMALL)}
            disabled={windowWidth > 1576 ? false : true}
          >
            <Icon
              type="icon anticon-icon content-41"
              className={styles.BoardViewIcon}
            />
          </Radio.Button>
        </div>
      </Radio.Group>
    </div>
  );
};
