export default t => ({
  element: "bpmn:ServiceTask",
  service: "bpiumGetCatalog",
  title: t("script.controlls.bpiumGetCatalog.title"),
  helplink: "",
  class: "bpium",
  color: "#7cab9e",
  border: 2,
  icon: "anticon-icon interface-55",
  offset_x: 2,
  offset_y: -16,
  toolbar_offset_y: -10,
  priority: 1,
  config: [
    {
      id: "commonSection",
      name: t("script.common.general"),
      type: "group"
    },
    {
      id: "name",
      name: t("script.common.title"),
      type: "text",
      readOnly: false,
      config: {
        defaultEmptyValue: t("script.controlls.bpiumGetCatalog.structure"),
        type: "text",
        valueType: "const",
        map: "input"
      }
    },
    {
      id: "description",
      name: t("script.common.description"),
      type: "text",
      readOnly: false,
      config: {
        type: "multiline",
        valueType: "const",
        map: "input"
      }
    },
    {
      id: "paramsSection",
      name: t("script.controlls.bpiumGetCatalog.getStructure"),
      type: "group"
    },
    {
      id: "catalogFormat",
      name: t("script.common.enterCatalog"),
      type: "dropdown",
      readOnly: false,
      config: {
        items: [
          {
            id: "catalog",
            name: t("script.common.fromList"),
            color: "E7E9ED"
          },
          {
            id: "var",
            name: t("script.common.fromVariables"),
            color: "E7E9ED"
          }
        ],
        defaultValue: true,
        multiselect: false,
        defaultEmptyValue: ["catalog"],
        map: "input"
      }
    },
    {
      id: "catalogId",
      name: t("script.common.catalog"),
      type: "radiobutton",
      readOnly: false,
      config: {
        items: [],
        type: "select",
        data: "catalogs",
        map: "input"
      },
      visible: {
        catalogFormat: {
          $include: "catalog"
        }
      }
    },
    {
      id: "catalogIdFromVar",
      name: t("script.common.catalogId"),
      type: "text",
      readOnly: false,
      config: {
        placeholder: t("script.common.numberExpression"),
        type: "text",
        theme: "script",
        map: "input"
      },
      visible: {
        catalogFormat: {
          $include: "var"
        }
      }
    },
    // {
    //   id: "fieldsFormat",
    //   name: t("script.controlls.bpiumGetCatalog.fields"),
    //   type: "dropdown",
    //   readOnly: false,
    //   config: {
    //     items: [
    //       {
    //         id: "all",
    //         name: t("script.controlls.bpiumGetCatalog.allFields"),
    //         color: "E7E9ED"
    //       },
    //       {
    //         id: "specified",
    //         name: t("script.controlls.bpiumGetCatalog.setFields"),
    //         color: "E7E9ED"
    //       }
    //     ],
    //     defaultValue: true,
    //     multiselect: false,
    //     defaultEmptyValue: ["all"],
    //     map: "input"
    //   }
    // },
    // {
    //   id: "fieldsSpecified",
    //   name: t("script.common.fieldsId"),
    //   type: "text",
    //   readOnly: false,
    //   config: {
    //     type: "multiline",
    //     theme: "script",
    //     placeholder: t("script.common.valueExpression"),
    //     map: "input"
    //   },
    //   hint: t("script.controlls.bpiumGetCatalog.fieldsSpecifiedHint"),
    //   visible: {
    //     fieldsFormat: {
    //       $include: "specified"
    //     }
    //   }
    // },
    {
      id: "section1",
      name: t("script.common.result"),
      type: "group"
    },
    {
      id: "output",
      name: t("script.common.saveTo"),
      type: "text",
      readOnly: false,
      hint: t("script.controlls.bpiumGetCatalog.outputHint"),
      config: {
        type: "text",
        map: "output",
        placeholder: t("script.common.variableName")
      }
    }
  ],
  exits: []
});
