import React from "react";
import PropTypes from "prop-types";
import cn from "classnames";
import Immutable from "immutable";

import ControlList from "../../common/UI/ControlList";
import SplitterPane from "../../common/Splitter";
import SideBarHeader from "./SideBarHeader";
import Sandbox from "./Sandbox";

import styles from "../script.less";

class ScriptSideBar extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      currentTab: "properties",
    }
  }

  onChangeTab = ( tabId ) =>  {
    this.setState({currentTab: tabId});
  }

  componentDidMount() {
   
  }

  render() {
    const {
      component,
      config,
      values,
      params,
      onChange,
      onClose,
      t
    } = this.props;

    const currentTab = this.state.currentTab;

    return (
      <SplitterPane
        key="splitter"
        componentName="bpmnEditor"
        defaultWidth={35}
      >
        <div className={cn(styles.sideBar, "bpmn-sidebar")}>
          <SideBarHeader
            component={component}
            currentTab={currentTab}
            onChangeTab={this.onChangeTab}
            onClose={onClose}
            t={t}
          />

          {currentTab == "properties" ? (
            <ControlList
              data={{
                configs: Immutable.fromJS(config || []),
                values: Immutable.fromJS(values || {})
              }}
              onChange={onChange}
              onEndEditing={onChange}
              keyForStorage={"script"}
            />
          ) : (
            <Sandbox
              component={component}
              params={params}
              t={t}
            />
          )}

        </div>
      </SplitterPane>
    );
  }
}

ScriptSideBar.propTypes = {
  component: PropTypes.object,
  config: PropTypes.object,
  values: PropTypes.object,
  onChange: PropTypes.func,
  onClose: PropTypes.func,
  t: PropTypes.func
};

export default ScriptSideBar;
