export default t => ({
  hidden: false,
  element: "bpmn:ServiceTask",
  service: "bpiumFileUpload",
  title: t("script.controlls.bpiumFileUpload.title"),
  helplink: "",
  class: "bpium",
  color: "#7cab9e",
  border: 2,
  icon: "anticon-icon files-39",
  offset_x: -2,
  offset_y: -15,
  toolbar_offset_y: -10,
  priority: 1,
  config: [
    {
      id: "commonSection",
      name: t("script.common.general"),
      type: "group"
    },
    {
      id: "name",
      name: t("script.common.title"),
      type: "text",
      readOnly: false,
      config: {
        defaultEmptyValue: t("script.controlls.bpiumFileUpload.title"),
        type: "text",
        valueType: "const",
        map: "input"
      }
    },
    {
      id: "description",
      name: t("script.common.description"),
      type: "text",
      readOnly: false,
      config: {
        type: "multiline",
        valueType: "const",
        map: "input"
      }
    },
    {
      id: "section_input",
      name: t("script.controlls.bpiumFileUpload.file"),
      type: "group"
    },
    {
      id: "file_format",
      name: t("script.controlls.bpiumFileUpload.file"),
      type: "dropdown",
      readOnly: false,
      config: {
        items: [
          {
            id: "url",
            name: t("script.controlls.bpiumFileUpload.fileUrl"),
            color: "E7E9ED"
          },
          {
            id: "data",
            name: t("script.controlls.bpiumFileUpload.fileData"),
            color: "E7E9ED"
          }
        ],
        defaultValue: true,
        multiselect: false,
        defaultEmptyValue: ["url"],
        map: "input"
      }
    },
    {
      id: "file_url",
      name: "URL",
      type: "text",
      readOnly: false,
      config: {
        type: "text",
        theme: "script",
        map: "input",
        placeholder: t("script.common.valueExpression")
      },
      visible: {
        file_format: {
          $include: "url"
        }
      }
    },
    {
      id: "file_data",
      name: t("script.controlls.bpiumFileUpload.fileContent"),
      type: "text",
      readOnly: false,
      config: {
        type: "multiline",
        theme: "script",
        map: "input",
        placeholder: t("script.common.valueExpression")
      },
      visible: {
        file_format: {
          $include: "data"
        }
      }
    },
    {
      id: "file_name",
      name: t("script.controlls.bpiumFileUpload.filename"),
      hint: t("script.controlls.bpiumFileUpload.filenameHint"),
      type: "text",
      readOnly: false,
      config: {
        type: "text",
        theme: "script",
        map: "input",
        placeholder: t("script.common.valueExpression")
      }
    },
    {
      id: "section_output",
      name: t("script.common.result"),
      type: "group"
    },
    {
      id: "output_format",
      name: t("script.common.saveAs"),
      type: "dropdown",
      readOnly: false,
      config: {
        items: [
          {
            id: "catalog",
            name: t("script.controlls.bpiumFileUpload.formatCatalog"),
            color: "E7E9ED"
          },
          {
            id: "fileId",
            name: t("script.controlls.bpiumFileUpload.formatId"),
            color: "E7E9ED"
          }
        ],
        defaultValue: true,
        multiselect: false,
        defaultEmptyValue: ["catalog"],
        map: "input"
      }
    },
    {
      id: "output_catalogId",
      name: t("script.common.catalog"),
      type: "radiobutton",
      readOnly: false,
      config: {
        items: [],
        type: "select",
        data: "catalogs",
        map: "input"
      },
      visible: {
        output_format: {
          $include: "catalog"
        }
      }
    },
    {
      id: "output_recordId",
      name: t("script.common.recordId"),
      type: "text",
      readOnly: false,
      config: {
        type: "text",
        theme: "script",
        map: "input",
        placeholder: t("script.common.numberExpression")
      },
      visible: {
        output_format: {
          $include: "catalog"
        }
      }
    },
    {
      id: "output_fieldId",
      name: t("script.controlls.bpiumFileUpload.field"),
      type: "radiobutton",
      readOnly: false,
      hint: t("script.controlls.bpiumFileUpload.fieldIdHint"),
      config: {
        items: [],
        type: "select",
        data: "fields",
        subtypes: ["file"],
        parent: "output_catalogId",
        valueType: "const",
        map: "input"
      },
      configOld: {
        type: "text",
        theme: "script",
        map: "input",
        placeholder: t("script.common.numberExpression")
      },
      visible: {
        output_format: {
          $include: "catalog"
        }
      }
    },
    {
      id: "output_fileId",
      name: t("script.common.fileId"),
      type: "text",
      readOnly: false,
      hint: t("script.controlls.bpiumFileUpload.fileIdHint"),
      config: {
        type: "text",
        map: "output",
        placeholder: t("script.common.variableName")
      },
      visible: {
        output_format: {
          $include: "fileId"
        }
      }
    },
    {
      id: "output_url",
      name: t("script.common.saveFileContent"),
      type: "text",
      readOnly: false,
      config: {
        type: "text",
        theme: "script",
        map: "output",
        placeholder: t("script.common.variable")
      }
    }
  ],
  exits: []
});
