import _ from "lodash";
import Immutable from "immutable";

import FIELD_TYPES from "../configs/fieldTypes";
import FieldApi from "../models/FieldApi";

export default {
  /*
   * Приводит конфиг path в формат ['{some}', '{1}', '{some}']
   *
   * @path {catalogId, fieldId}
   *
   * @returns ['catalogs', '{catalogId}', 'filters', 'fields', '{fieldId}']
   */
  getViewFilterPath({ sceneId, viewId, fieldId }) {
    if (Number(viewId) === 0) {
      viewId = "$new";
    }
    return ["scenes", sceneId, "views", viewId, "filters", fieldId];
  },

  getViewFiltersPath({ sceneId, viewId }) {
    return ["scenes", sceneId, "views", viewId, "filters"];
  },

  getFiltersPath({ sceneId }) {
    return ["scenes", sceneId, "data", "filters"];
  },

  // getCatalogFieldPath(path, catalogId) {
  //   return ['catalogs', catalogId, 'views', 'filters', 'fields', path.fieldId];
  // },

  // getCatalogFields(catalogId) {
  //   // return ['currentCatalog', 'filters', 'fields'];
  //   return ['catalogs', catalogId, 'views', 'filters', 'fields'];
  // },

  /**
   * @param type field
   * @param value
   */
  convertFilterValueForRequestByFieldType(value, type, field) {
    switch (type) {
      case FIELD_TYPES.USER:
        return _.map(value, item => item.id);
      case FIELD_TYPES.OBJECT:
        return _.map(value, item => {
          value = _.pick(item, ["catalogId", "recordId", "filters"]);
          if (value.filters) {
            const extendedCatalogFields = field.getIn([
              "config",
              "fields",
              item.catalogId
            ]);
            value.filters = this.getFiltersForRequest(
              value.filters,
              extendedCatalogFields
            );
          }
          return value;
        });

      default:
        return value;
    }
  },

  mergeFilters(object1, object2) {
    if (!object1) {
      return object2;
    }

    if (!object2) {
      return object1;
    }

    const immutableCase =
      Immutable.Map.isMap(object1) && Immutable.Map.isMap(object2);

    if (immutableCase) {
      const merger = (oldVal, newVal) => {
        if (Immutable.List.isList(oldVal) && Immutable.List.isList(newVal)) {
          return oldVal.concat(newVal);
        } else {
          return oldVal.merge(newVal);
        }
      };

      return object1.mergeWith(merger, object2);
    } else {
      object1 = object1 && object1.toJS ? object1.toJS() : object1;
      object2 = object2 && object2.toJS ? object2.toJS() : object2;

      const merger = (oldVal, newVal) => {
        if (_.isArray(oldVal) && _.isArray(newVal)) {
          return oldVal.concat(newVal);
        } else {
          return oldVal.merge(newVal);
        }
      };

      return _.mergeWith(object1, object2, merger);
    }
  },

  getFiltersForRequest(filters, fields) {
    // code moved from stores/filtersMixin.js
    let result = [];
    for (let fieldId in filters) {
      if (filters.hasOwnProperty(fieldId)) {
        let fieldFromCatalog = fields.find(f => f.get("id") === fieldId);
        if (!fieldFromCatalog) {
          console.warn('not found field "%s" for filter', fieldId);
        } else {
          let type = fieldFromCatalog.get("type");
          const filter = filters[fieldId];
          for (const { value } of filter) {
            if (!_.isEmpty(value) || value === 0) {
              result.push({
                fieldId,
                value: this.convertFilterValueForRequestByFieldType(
                  value,
                  type,
                  fieldFromCatalog
                )
              });
            }
          }
        }
      }
    }

    return result;
  },

  // filters for field visibility
  convertFiltersToNewFormat(filters) {
    return _.reduce(
      filters,
      (hash, filter, fieldId) => {
        if ((_.isArray(filter) || _.isObject(filter)) && _.isEmpty(filter)) {
          return hash;
        }

        const filterInNewFormat =
          _.isArray(filter) && _.get(filter, ["0", "value"]);

        if (!filterInNewFormat) {
          filter = [{ value: filter }];
        }

        hash[fieldId] = filter;

        return hash;
      },
      {}
    );
  },

  // filters for field visibility
  convertFiltersToPrevFormat(filters) {
    filters = !Immutable.Map.isMap(filters)
      ? Immutable.fromJS(filters)
      : filters;

    filters = filters
      .filter(filtersByFieldId => {
        return filtersByFieldId && filtersByFieldId.size > 0;
      })
      .map(filtersByFieldId => {
        const value = filtersByFieldId.getIn(["0", "value"]);
        return value;
      });

    return filters;
  },

  prepareWidgetFilters(widget) {
    const { recordsFilter, ...otherWidhetProps } = widget;

    let filters = recordsFilter && recordsFilter.filters;

    if (!filters) {
      return widget;
    }

    filters = this.convertFiltersToNewFormat(filters);

    recordsFilter.filters = filters;
    return {
      ...otherWidhetProps,
      recordsFilter
    };
  },

  prepareWidgetsFilters(widgets) {
    return widgets.map(widget => {
      return this.prepareWidgetFilters(widget);
    });
  },

  prepareWidgetFiltersForRequest(widget) {
    const filtersPath = ["recordsFilter", "filters"];

    let filters = widget.getIn(filtersPath);

    if (!filters) {
      return widget;
    }

    // в графиках фильтры отправляются в запросах в прежней структуре (fieldId: value), отличной от структуры фильтров в записях ({ fieldId, value }), поэтмоу берем первый фильтр, тк можно отправить только один фильтр
    filters = this.convertFiltersToPrevFormat(filters);

    widget = widget.setIn(filtersPath, filters);

    return widget;
  }
};
